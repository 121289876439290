import {
	RouteReuseStrategy,
	ActivatedRouteSnapshot,
	DetachedRouteHandle,
} from '@angular/router';
export class CustomRouteReuseStrategy implements RouteReuseStrategy {
	private handlers: { [key: string]: DetachedRouteHandle } = {};

	constructor() {}

	shouldDetach(route: ActivatedRouteSnapshot): boolean {
		if (!route.routeConfig || route.routeConfig.loadChildren) {
			return false;
		}
		let shouldReuse: boolean = false;
		if (route.routeConfig.data) {
			shouldReuse = !!route.routeConfig.data['reuse'];
		}
		return shouldReuse;
	}

	store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
		if (handle) {
			this.handlers[this.getUrl(route)] = handle;
		}
	}

	shouldAttach(route: ActivatedRouteSnapshot): boolean {
		return !!this.handlers[this.getUrl(route)];
	}

	retrieve(route: ActivatedRouteSnapshot): any {
		if (!route.routeConfig || route.routeConfig.loadChildren) {
			return null;
		}

		return this.handlers[this.getUrl(route)];
	}

	shouldReuseRoute(
		future: ActivatedRouteSnapshot,
		current: ActivatedRouteSnapshot
	): boolean {
		let reUseUrl: boolean = false;
		if (future.routeConfig) {
			if (future.routeConfig.data) {
				reUseUrl = !!future.routeConfig.data['reuse'];
			}
		}
		const defaultReuse: boolean = future.routeConfig === current.routeConfig;
		return reUseUrl || defaultReuse;
	}

	getUrl(route: ActivatedRouteSnapshot): any {
		if (route.routeConfig) {
			const url: any = route.routeConfig.path;
			return url;
		}
	}
}
