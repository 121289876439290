import { Injectable, Provider } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { StorageKeys } from '../constants/storage-keys';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';

@Injectable()
export class AuthTokenHttpInterceptor implements HttpInterceptor {
  constructor(
    private _authService: AuthService,
    private _sessionStorage: SessionStorageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const accessToken: string =
      this._authService.idToken ||
      this._sessionStorage.getString(StorageKeys.idTokenKey);
    if (accessToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
          'Access-Control-Allow-Origin': '*',
        },
      });
    }

    return next.handle(request);
  }
}

export const AuthTokenHttpInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthTokenHttpInterceptor,
  multi: true,
};
