import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppLoaderService {
  private _loaderConfig: BehaviorSubject<any> = new BehaviorSubject(false);

  constructor() {}

  public showLoader() {
    this._loaderConfig.next(true);
  }

  public hideLoader() {
    this._loaderConfig.next(false);
  }

  get getLoaderConfig$(): Observable<any> {
    return this._loaderConfig.asObservable();
  }
}
