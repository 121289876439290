import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
class PermissionsService {
  constructor(public authService: AuthService, private router: Router) {}
  canActivate(): any {
    console.log('isLoggedIn', this.authService.isLoggedIn);
    const isLoggedIn: boolean = this.authService.isLoggedIn;
    if (!isLoggedIn) {
      console.warn('access denied');
      try {
        if (sessionStorage.length > 0) {
          sessionStorage.clear();
        }
        if (localStorage.length > 0) {
          localStorage.clear();
        }
      } catch (e) {
        console.warn(e);
      }
      if (sessionStorage.length > 0) {
        sessionStorage.clear();
      }
      if (localStorage.length > 0) {
        localStorage.clear();
      }
      this.router.navigate(['authentication']);
    }
    return isLoggedIn;
  }
}

export const AuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  return inject(PermissionsService).canActivate();
};
