export const StorageKeys: {
	idTokenKey: string;
	userKey: string;
	tokenExpDate: string;
	schemaKey: string;
	changePassword?: string;
	currentSite?: string;
	subNavItems: string;
	siteMappingDetails: string;
} = {
	idTokenKey: 'access_token',
	userKey: 'user',
	tokenExpDate: 'token_exp_date',
	schemaKey: 'schemaName',
	changePassword: 'psw_change_required',
	currentSite: 'current_selected_site',
	subNavItems: 'menu_items',
	siteMappingDetails: 'user_sites_data',
};
