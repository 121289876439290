export const environment = {
  userIdleConfig: {
    idle: 1200,
    timeout: 300,
    ping: 180
  },
  bsiAuthUserConfig: {
    appKey: '',
    bearerToken: '',
    apiBaseUrl: 'https://bsibackgrounds.com:8080',
    baseControllerPath: 'api',
    endpoints: {
      signin: {
        method: 'POST',
        path: 'api/auth/signin'
      },
      sendpdfconsent: {
        method: 'POST',
        path: 'api/partner-master/send-pdf-consent'
      },
      getPartnerList: {
        method: 'GET',
        path: 'api/partner-master'
      },
      addPartner: {
        method: 'POST',
        path: 'api/partner-master'
      },
      updatePartner: {
        method: 'PUT',
        path: 'api/partner-master'
      },
      getPdfConsent: {
        method: 'GET',
        path: 'api/partner-master/get-pdf-consent'
      },
      getDownloadConsent: {
        method: 'GET',
        path: 'api/partner-master/download-consent'
      },
      getProfile: {
        method: 'GET',
        path: 'api/admin'
      },
      updateProfile: {
        method: 'POST',
        path: 'api/auth/signup'
      },
      getUploadedDocuments: {
        method: 'GET',
        path: 'api/admin/get-uploaded-documents'
      },
      changePassword: {
        method: 'POST',
        path: 'api/auth/change-password'
      },
      forgotpassword: {
        method: 'POST',
        path: 'api/partner-master/partner-reset-password'
      },
      getDownloadCsv: {
        method: 'GET',
        path: 'api/admin/download-template'
      }
    }
  },
  edc: {
    pbck: `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCn+VDC1CFGkev40VjbAEaQlM1bRMytjWZE6YmnOj+ZSYi3/XLg/qnDjnw+8ovGo+8ZhaWfbZJQIr8Y9SXm4USsKolGB/UVbx4Nw14FXxiJZM6/+FHMtKEZP7e3wSKgyOex3vLJWl/nn3O+hgK6bzfe4b6hjWcVeN5Dj52XJceGhwIDAQAB`,
    pvtk: `MIICdQIBADANBgkqhkiG9w0BAQEFAASCAl8wggJbAgEAAoGBAKf5UMLUIUaR6/jRWNsARpCUzVtEzK2NZkTpiac6P5lJiLf9cuD+qcOOfD7yi8aj7xmFpZ9tklAivxj1JebhRKwqiUYH9RVvHg3DXgVfGIlkzr/4Ucy0oRk/t7fBIqDI57He8slaX+efc76GArpvN97hvqGNZxV43kOPnZclx4aHAgMBAAECgYBgkKepGaJ3m3P4+wEGBcaLX8Ae9BJmw59Dhq6oAIiHawq6G25rgnu9/K/Y0D8i1yOhnH92g9tVTmRlyKkdbeu3zUxFbs8lxACs+sBnilV8DHR0+P5YZgHTNokXyXwmCi+3nvIkI7IyD50JAiwu2rBqiLANoMOz9dLEGGf/uUsKIQJBAPAnIMYNeXmD4UO+SK6K+1MVqsfzuTcZ4WUllT4yjZ/naqP3kG0NHdcECsZdqzkRoiEIDEVRFrVANg6BQQKb9+MCQQCzDuCb+OscV9Ks7BFOicLIlKA6NgEySAS6OcJ/Usrm+Jn5AXPdtOuG8NpgIRkO2nQi5MyO7sxIO5/JtJfLF1ANAkA8quXQop7JX9OMdYmO7nVHK/YUxTFJBjS2hKQLLmIwvxlliqjlo6aiJtdB/W+/3wCCL7JJ4I5IA4ykZWqy6gvZAkBbJSeQPZTwSIQlIcVthRJxp7Yn63WQcrh1UxF+XlaKW+GErwibwoxhlBiU9kQbG3giivTVjRjPbApaUmMyLhvBAkBKfvc2v2VnrzOsH3rdjeI+TNX5wuq9wTaN1S1gaIzPitF8UJZtDYhUYLsYmHQVi1UCKYFhcN+iIG69GDpbOslh`,
    enabled: true
  }
};
