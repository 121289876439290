import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';
import { AuthService } from './auth/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'BSI_UI';
  constructor(private _router: Router, private _authService: AuthService) {
    this._router.events
      .pipe(
        filter(
          (event): event is NavigationStart => event instanceof NavigationStart
        )
      )
      .subscribe((event: NavigationStart) => {
        if (
          event.url === '/authentication/login' &&
          !!this._authService.isLoggedIn
        ) {
          this._router.navigateByUrl('');
        }
      });
  }
}
