import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { environment } from 'src/environments/environment';
import { StorageKeys } from '../constants/storage-keys';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  get isLoggedIn(): boolean {
    if (this._idToken === null) {
      this.SignOut();
    }
    const user: any = this._sessionStorage.get(StorageKeys.userKey);
    return !!user ? true : false;
  }

  get idToken(): string {
    return this._idToken;
  }

  get user(): any {
    return this.isLoggedIn ? this.user$.value || null : null;
  }
  private user$: BehaviorSubject<any> = new BehaviorSubject(
    this._sessionStorage.get(StorageKeys.userKey)
  );
  private _idToken: any = this._sessionStorage.getString(
    StorageKeys.idTokenKey
  );
  private _bsiAuthUserConfig: any = environment.bsiAuthUserConfig;
  private _bsiAuthBaseUrl: string = this._bsiAuthUserConfig.apiBaseUrl || '';
  constructor(
    public router: Router,
    public ngZone: NgZone,
    private _http: HttpClient,
    private _sessionStorage: SessionStorageService
  ) {
    this.user$ = new BehaviorSubject<any>(
      this._sessionStorage.get(StorageKeys.userKey)
    );

    this.user$.subscribe((user: any) => {
      if (user) {
        this._sessionStorage.set(StorageKeys.userKey, user);
      } else {
        this._sessionStorage.set(StorageKeys.userKey, null);
      }
    });
  }
  // Sign in with email/password
  SignIn(email: string, password: string) {
    return new Promise<void>((resolve, reject) => {
      if (!!this.user) {
        resolve(this.user);
        console.log('Already Signed In !');
      }
      const apiURL = `${this._bsiAuthBaseUrl}/${this._bsiAuthUserConfig.endpoints.signin.path}`;
      this._http
        .post<any>(apiURL, {
          username: email,
          password: password,
        })
        .subscribe({
          next: (res: any) => {
            if (res.data) {
              res = !!res.data ? res.data : null;
              this._idToken = !!res.accessToken ? res.accessToken : null;
              this._sessionStorage.set(StorageKeys.idTokenKey, this._idToken);
              this.user$.next(res);
              this.ngZone.run(() => {
                this.router.navigate(['dashboard']);
              });
              resolve(res);
            } else {
              reject(res.errors[0]);
            }
          },
          error: (error: any) => {
            if (!this.idToken) {
              this.SignOut();
              this.user$.next(null);
            }
            reject(error);
          },
        });
    });
  }
  SignOut() {
    this.user$.next(null);
    this._idToken = null;
    try {
      this._sessionStorage.clearAll();
    } catch (e) {
      console.warn(e);
    }
    this.router.navigate(['authentication']);
  }
}
