import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AppLoaderService } from '../../services/app-loader.service';
import { skipWhile, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { spinner } from 'src/app/constants/spinner';

@Component({
  selector: 'app-app-loader',
  standalone: true,
  imports: [CommonModule, NgxSpinnerModule],
  templateUrl: './app-loader.component.html',
  styleUrls: ['./app-loader.component.scss'],
})
export class AppLoaderComponent implements OnInit, OnDestroy {
  @Input() loaderName: string = 'global';
  private componentDestroyed$: Subject<void> = new Subject();

  get spinnerConfig(): any {
    return spinner;
  }
  constructor(
    private loaderSvc: AppLoaderService,
    private spinnerSvc: NgxSpinnerService
  ) {
    this.loaderSvc.getLoaderConfig$
      .pipe(
        skipWhile((res) => !res),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((config: any) => {
        if (config) {
          this.spinnerSvc.show();
        } else {
          this.spinnerSvc.hide();
        }
      });
  }

  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }
}
