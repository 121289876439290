import { NgModule } from '@angular/core';
import { Routes, RouterModule, RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStrategy } from './app-route-reuse-strategy';
import { AuthGuard } from './auth/guards/auth-guard';

import { BaseComponent } from './core/components/base/base.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/core/core-routes').then((mod) => mod.CORE_ROUTES),
  },
  {
    path: 'authentication',
    loadChildren: () =>
      import('./auth/auth-routes').then((mod) => mod.AUTH_ROUTES),
  },
];

// configures NgModule imports and exports
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
